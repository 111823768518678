import React from 'react'
import './Service.css'
import uiux from '../../Assets/image_2024-10-13_142649796-removebg-preview.png'
import web from '../../Assets/web-removebg-preview.png'
import app from '../../Assets/image_2024-10-13_144052092-removebg-preview.png'
import graphics from '../../Assets/image_2024-10-13_142806622-removebg-preview.png'

const Service = () => {
  return (
<div className="service" id='Services'>

<div className="s-serv">
    <span>Services</span>
    <span>I provide tailored web development, innovative UI/UX design, and striking graphic design to elevate your brand. <br />
     Additionally, I specialize in app development, creating seamless and engaging experiences for users. 
     <br /> Let’s bring your vision to life!<br />
    </span>
</div>
<div className="s-service">
    <div className="s-container">
        <img src={uiux} alt="" className="s-image1"/>
        <span>UI/UX</span>
        <span>I craft intuitive and engaging user <br />
            experiences that enhance usability and <br />
            ensuring your digital products stand out.
        </span>
    </div>

    <div className="s-container">
        <img src={web} alt="" className="s-image2"/>
        <span>Web Design </span>
        <span>I build responsive and scalable websites <br />
         that drive performance and engagement, <br />
          tailored to meet your business needs.<br />
       </span>
    </div>

    <div className="s-container">
        <img src={app} alt="" className="s-image3" />
        <span>App Design</span>
        <span>I create intuitive and user-friendly <br />
         mobile applications that enhance user experience <br />
         and drive engagement, from concept to launch. <br />
      </span>
    </div>


    <div className="s-container">
        <img src={graphics} alt="" className="s-image4" />
        <span>Graphic Design </span>
        <span>I design visually compelling graphics <br />
        that  effectively communicate your brand's <br />
        message and captivate your audience. <br />
       </span>
    </div>
</div>
</div>
  )
}

export default Service