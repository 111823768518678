import React, { useState } from 'react';
import './Navbar.css';
import subtract from '../../Assets/Subtract.png';
import muiz1 from '../../Assets/mz 1.png'
import muiz2 from '../../Assets/mz 2.png'
import olascom from '../../Assets/Olascom L 1.png'
import tech from '../../Assets/tech-removebg-preview.png'
import { Link } from 'react-scroll'; // Assuming you're using react-scroll for smooth scrolling

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseMenu = () => {
    setIsOpen(false); // Close the menu when a link is clicked
  };

  return (
    <div className="navbar" id='Navbar'>
      <div className="n-logo">
     
        <img src={tech} alt="logo" className="n-img" />
        <div className="n-name">olascom Tech</div>
      </div>
    

      {/* Hamburger icon for mobile */}
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {/* Navigation links */}
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <Link spy={true} to="Navbar" smooth={true} activeClass="activeClass" onClick={handleCloseMenu}>
          <li>Home</li>
        </Link>
        <Link spy={true} to="About Me" smooth={true}  onClick={handleCloseMenu}>
          <li>About Me</li>
        </Link>
        <Link spy={true} to="Services" smooth={true}  onClick={handleCloseMenu}>
          <li>Services</li>
        </Link>
        <Link spy={true} to="Testimonial" smooth={true}  onClick={handleCloseMenu}>
          <li>Testimonial</li>
        </Link>

        <Link spy={true} to="Project" smooth={true}  onClick={handleCloseMenu}>
          <li>Project</li>
        </Link>
        <Link spy={true} to="Contact" smooth={true}  onClick={handleCloseMenu}>
          <li>Contact</li>
        </Link>
      </ul>

      <button type="submit" className="n-button">
        Download CV
      </button>
    </div>
  );
};

export default Navbar;
