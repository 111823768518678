import React from 'react';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from './components/About/About';
import Hero from './components/Hero/Hero';
import Navbar from './components/Navbar/Navbar';
import Project from './components/Project/Project';
import Service from './components/Service/Service';
import Testimonials from './components/Testimonials/Testimonials';
import Footer from './components/Footer/Footer';
import LastFooter from './components/LastFooter/LastFooter';
function App() {
  return (
    <div className="App">
      <Navbar/>
      <Hero/>
      <About/>
      <Service/>
      <Project/>
      <Testimonials/>
      <Footer/>
      <LastFooter/>
    </div>
  );
}

export default App;
