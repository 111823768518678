import React, { useEffect, useState } from 'react';
import './About.css';
import muiz2 from '../../Assets/muiz.jpg'

const About = () => {
  const [values, setValues] = useState({
    uiux: 0,
    softwareDev: 0,
    webDev: 0,
    appDev: 0,
    graphicsDesign: 0,
    desktopPublishing: 0
  });

  const targetValues = {
    uiux: 70,
    softwareDev: 60,
    webDev: 80,
    appDev: 70,
    graphicsDesign: 80,
    desktopPublishing: 90
  };

  // Animate the progress bars
  useEffect(() => {
    const animateProgress = () => {
      const interval = setInterval(() => {
        setValues((prevValues) => {
          const newValues = {};
          let allValuesReached = true;

          for (const key in targetValues) {
            if (prevValues[key] < targetValues[key]) {
              newValues[key] = prevValues[key] + 1; // Increment value
              allValuesReached = false; // Not all values reached yet
            } else {
              newValues[key] = prevValues[key]; // Keep it the same
            }
          }

          // Clear interval if all target values are reached
          if (allValuesReached) {
            clearInterval(interval);
          }

          return { ...prevValues, ...newValues };
        });
      }, 20); // Adjust the interval timing as needed

      return () => clearInterval(interval); // Cleanup
    };

    animateProgress();
  }, []);

  // Handle slider change for each category
  const handleChange = (event, category) => {
    setValues({ ...values, [category]: event.target.value });
  };

  return (
    <div className="about" id='About Me'>
      <div className="a-left">
        <img src={muiz2} alt="Group" className="a-img" />
      </div>

      <div className="a-right">
        <span>About Me</span>
        <span>
        I am a tech enthusiast skilled in UI/UX design, coding, and graphics.  <br />
        With a passion for creative problem-solving,  I deliver innovative
          <br />
          solutions and   enjoy teaching others in the tech space.
          <br />
        
        </span>

        {/* UI/UX Design */}
        <div className="category-slider">
          <h4>UI/UX Design</h4>
          <input
            type="range"
            min="0"
            max="100"
            value={values.uiux}
            onChange={(event) => handleChange(event, 'uiux')}
            className="slider"
          />
          <div className="slider-value">Value: <span>{values.uiux}</span></div>
        </div>

        {/* Software Development */}
        <div className="category-slider">
          <h4>Software Development</h4>
          <input
            type="range"
            min="0"
            max="100"
            value={values.softwareDev}
            onChange={(event) => handleChange(event, 'softwareDev')}
            className="slider"
          />
          <div className="slider-value">Value: <span>{values.softwareDev}</span></div>
        </div>

        {/* Web Development */}
        <div className="category-slider">
          <h4>Web Development</h4>
          <input
            type="range"
            min="0"
            max="100"
            value={values.webDev}
            onChange={(event) => handleChange(event, 'webDev')}
            className="slider"
          />
          <div className="slider-value">Value: <span>{values.webDev}</span></div>
        </div>

        {/* App Development */}
        <div className="category-slider">
          <h4>App Development</h4>
          <input
            type="range"
            min="0"
            max="100"
            value={values.appDev}
            onChange={(event) => handleChange(event, 'appDev')}
            className="slider"
          />
          <div className="slider-value">Value: <span>{values.appDev}</span></div>
        </div>

        {/* Graphics Design */}
        <div className="category-slider">
          <h4>Graphics Design</h4>
          <input
            type="range"
            min="0"
            max="100"
            value={values.graphicsDesign}
            onChange={(event) => handleChange(event, 'graphicsDesign')}
            className="slider"
          />
          <div className="slider-value">Value: <span>{values.graphicsDesign}</span></div>
        </div>

        {/* Desktop Publishing */}
        <div className="category-slider">
          <h4>Desktop Publishing</h4>
          <input
            type="range"
            min="0"
            max="100"
            value={values.desktopPublishing}
            onChange={(event) => handleChange(event, 'desktopPublishing')}
            className="slider"
          />
          <div className="slider-value">Value: <span>{values.desktopPublishing}</span></div>
        </div>
      </div>
    </div>
  );
};

export default About;
