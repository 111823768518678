import React from 'react'
import './LastFooter.css'
import subtract from '../../Assets/Subtract.png'
import facebook from '../../Assets/Facebook - Negative.png'
import linkedin from '../../Assets/LinkedIn - Negative.png'
import instag from '../../Assets/Instagram - Negative.png'
import twitter from '../../Assets/Vector.png'
import olascom from '../../Assets/Olascom L 1.png'
import tech from '../../Assets/tech-removebg-preview.png'
import { Link } from 'react-scroll'
const LastFooter = () => {
  return (
    <div className="lastfooter">
<Link spy={true} to="Navbar" smooth={true} activeClass="activeClass" >
       
        <div className="img-last">
            
            <img src={tech} alt="" /> 
            <h1>Olascom Tech</h1>
        
        </div>
          </Link>

        <div className="nav-last">
            <ul>
                

                <Link spy={true} to='Navbar' smooth={true} activeClass="activeClass">
                <li> Home </li>
                </Link>
                <Link spy={true} to='About Me' smooth={true} activeClass="activeClass">
                <li> About Me </li>
                </Link>
                <Link spy={true} to='Services' smooth={true} activeClass="activeClass">
                <li> Servives </li>
                </Link>
                <Link spy={true} to='Testimonial' smooth={true} activeClass="activeClass">
                <li>Testimonial</li>
                </Link>
                <Link spy={true} to='Project' smooth={true} activeClass="activeClass">
                <li>Project</li>
                </Link>
                <Link spy={true} to=' Contact' smooth={true} activeClass="activeClass">
                <li>Contact</li>
                </Link>
            </ul>
        </div>

        <div className="s-icon">
            <a href=" https://wwww.facebook.com/muhammed.olaniyan.3/">    <img src={facebook} alt="" className="icon"/> </a>
             <a href="https://www.x.com"> <img src={twitter} alt="" className="icon"/> </a>
            <a href="https://www.instagram.com/olasu_nkanmi251/">  <img src={instag} alt="" className="icon"/> </a>
            <a href=" https://www.linkedin.com/in/olasunkanmi-abdulmuiz-9a18b9298/">    <img src={linkedin} alt="" className="icon"/></a>
            </div>

            <div className="b-lf">
                &copy;2024 <span>Olascom</span> All Right Reserved, inc.
            </div>
    </div>
  )
}

export default LastFooter