import React from 'react';
import './Footer.css';

const Footer = () => {

  return (
    <div className="footer" id="Contact">
      <div className="f-footer">
        <span>Let's Design Together</span>
        <span>
        At Olascom Tech, we collaborate to bring your visions to life. Whether it's enhancing your digital 
        <br />presence or developing applications, let's create something amazing together!
        </span>
      </div>
      <form>
        <div className="e-footer">
          <div className="i-footer">
            <input type="email" placeholder="Enter Your Email" required />
          </div>
          <div className="b-footer">
            <button type="submit">Contact Me</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Footer;
