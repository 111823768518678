import React from 'react'
import './Hero.css'
import facebook from '../../Assets/Facebook - Negative.png'
import linkedin from '../../Assets/LinkedIn - Negative.png'
import instag from '../../Assets/Instagram - Negative.png'
import twitter from '../../Assets/Vector.png'
import muiz1 from '../../Assets/muiz.jpg'
const Hero = () => {
  return (
    <div className="hero" id='Home'>
        <div className="h-left">
            <span>Hi!   I am </span>
            <span>Olasunkanmi Abdulmuiz</span>
            <span>Multidisciplinary</span>
            <span>Tech Professional</span>
            <span>
            I am a multidisciplinary tech professional specializing in 
            UI/UX design, coding, desktop publishing, graphics design,
             and IT training.  I blend creativity and technical skills to deliver
             innovative solutions and empower others through IT education.</span>
                <button className="h-hire"> Hire Me</button>

        </div>
        <div className="h-right"> 
            <div className="i-right">
            <img src={muiz1} alt="" className="r-img"/>
            <div className="s-icon">
              <a href="https://wwww.facebook.com/muhammed.olaniyan.3/"> <img src={facebook} alt="" className="icon"/> </a>
              <a href="https://www.x.com"><img src={twitter} alt="" className="icon"/> </a>
              <a href="https://www.instagram.com/olasu_nkanmi251/"> <img src={instag} alt="" className="icon"/> </a>
              <a href="https://www.linkedin.com/in/olasunkanmi-abdulmuiz-9a18b9298/"> <img src={linkedin} alt="" className="icon"/> </a>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Hero