import React from 'react';
import './Testimonials.css';
import Slider from 'react-slick';
import ellipse from '../../Assets/Ellipse 10.png'
import group from '../../Assets/Group 7.png'
import tech from '../../Assets/tech.jpg'
import vast from '../../Assets/vast.jpg'
import muiz from '../../Assets/muiz.jpg'

const Testimonials = () => {
  const people = [
    {
      id: 1,
      image: tech, // Replace with the correct path to the image
      name: 'OlascomTech',
      post: 'Web Development',
      quote: 'Innovation distinguishes between a leader and a follower.',
    },
    {
      id: 2,
      image: group,
      name: 'Jane Smith',
      post: 'UI/UX Designer',
      quote: 'Design is not just what it looks like, design is how it works.',
    },
    {
      id: 3,
      image: ellipse,
      name: 'Alice Brown',
      post: 'Project Manager',
      quote: 'Success is not the key to happiness. Happiness is the key to success.',
    },
    {
      id: 4,
      image: vast,
      name: 'Vast Agency',
      post: 'App Developer',
      quote: 'The only limit to our realization of tomorrow is our doubts of today.',
    },
    {
      id: 5,
      image: muiz,
      name: 'Olamilekan Abdulmuiz',
      post: 'Graphics Designer',
      quote: 'Creativity is intelligence having fun.',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <div className="testimonials" id='Testimonial'>
      <div className="t-testimonials">
        <span>Testimonials</span>
        <span>
        Hear from my clients about their experiences working with me, <br /> 
        highlighting the impact of my designs and development solutions on their projects. 
        
        </span>
      </div>

      <div className="carousel-container">
        <Slider {...settings}>
          {people.map((person) => (
            <div key={person.id} className="carousel-item">
              <div className="carousel-content">
                <img src={person.image} alt={person.name} className="carousel-image" />
                <div className="carousel-text">
                  <p className="quote">"{person.quote}"</p>
                  <p className="name">{person.name}</p>
                  <p className="post">{person.post}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
