import React from 'react'
import './project.css'
import group24 from '../../Assets/Group 24.png'
import group42 from '../../Assets/Group 42.png'
import group43 from '../../Assets/Group 43.png'
const Project = () => {
  return (
   <div className="project" id='Project'>

    
<div className="p-project">
    <span>My Projects</span>
    <span>Explore my diverse portfolio showcasing innovative web and app designs, <br />
     user-centric interfaces, and visually striking graphics that elevate brands and engage users.
</span>
</div>
<div className="pm-project">
    <span>All</span>
    <span>UI/UX</span>
    <span>Web Design</span>
    <span>App Design</span>
    <span>Graphic Design</span>

</div> 

<div className="p-contain">

<div className="ps-contain"> 
<div className="p-image">
    <img src={group24} alt="" className="image-p"/>
</div>
<span>Web Design </span>
<span>AirCalling Landing Page Design </span>
</div>


<div className="ps-contain"> 
<div className="p-image">
    <img src={group43} alt="" className="image-p"/>
</div>
<span>Web Design </span>
<span>Business Landing Page Design  </span>
</div>

<div className="ps-contain"> 
<div className="p-image">
    <img src={group42} alt="" className="image-p"/>
</div>
<span>Web Design </span>
<span>Ecom Web Page Design  </span>
</div>




</div>
</div>

  )
}

export default Project